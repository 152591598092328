import React, { Component } from 'react';

class AboutUs extends Component {
    render() {
        return (
            <div className="about-us">
                <div>
                    <p>
                        MakeStudio is a multidisciplinary creative studio.We work together to
                        design, create and produce work that we are proud of for folks that we believe in.
                        We are available for hire in a wide range of creative disciplines for a variety of
                        jobs, projects and gigs
                    </p>
                </div>
            </div>
        )
    }
}

export default AboutUs;