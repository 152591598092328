import React, { Component } from 'react';
import Modal from 'antd/lib/modal/Modal';
import ReactPlayer from 'react-player';

class Home extends Component {
    state = { visible: false };
    showVideo(key) {
        const urls = {
            "1": "https://vimeo.com/451849301",
            "2": "https://vimeo.com/450102217",
            "3": "https://vimeo.com/460057034",
            "4": "https://vimeo.com/451848868",
            "5": "https://vimeo.com/451848358",
            "6": "https://vimeo.com/477964464",
            "7": "https://vimeo.com/477964464",
            "8": "https://vimeo.com/477962672",
            "9": "https://vimeo.com/451849649",
            "10": "https://vimeo.com/460057322"
        }
        this.setState({
            visible: true,
            url: urls[key]
        });
    }
    handleOk = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 3000);
    }
    handleCancel = () => {
        this.setState({ visible: false });
    }
    render() {
        return (
            <div className="container-fluid home">
                <div className="row">
                    <div className="col-sm-12 col-lg-12 col-xl-12 custom-padding parent">
                        <div className="inner">
                            <div className="box child article1" onClick={() => this.showVideo("1")}>
                                <span>Chintu ka Birthday</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xl-6 custom-padding parent">
                        <div className="inner">
                            <div className="box child article2" onClick={() => this.showVideo("2")}>
                                <span>Uper Niche</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 custom-padding parent">
                        <div className="inner">
                            <div className="box child article3" onClick={() => this.showVideo("3")}>
                                <span>Chavanni Lal</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 custom-padding">
                        <div className="row cust-height" style={{ height: "100%" }}>
                            <div className="col-sm-12 parent">
                                <div className="inner">
                                    <div className="box child article4" onClick={() => this.showVideo("4")}>
                                        <span>Network Menifesto</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 parent cmt-3">
                                <div className="inner">
                                    <div className="box child article5" onClick={() => this.showVideo("5")}>
                                        <span>TVS</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12 custom-padding parent">
                        <div className="inner">
                            <div className="box child article6" onClick={() => this.showVideo("6")}>
                                <span>Tasveer</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xl-6 custom-padding parent">
                        <div className="inner">
                            <div className="box child article7" onClick={() => this.showVideo("7")}>
                                <span>Missing</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-xl-3 custom-padding parent">
                        <div className="inner">
                            <div className="box child article8" onClick={() => this.showVideo("8")}>
                                <span>Alive and Kicking</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 custom-padding">
                        <div className="row cust-height" style={{ height: "100%" }}>
                            <div className="col-sm-12 parent">
                                <div className="inner">
                                    <div className="box child article9" onClick={() => this.showVideo("9")}>
                                        <span>MAMI PROMO</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 parent cmt-3">
                                <div className="inner">
                                    <div className="box child article10" onClick={() => this.showVideo("10")}>
                                        <span>On the Road Stories</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    // title="Basic Modal"
                    visible={this.state.visible}
                    centered
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    className="video"
                >
                    <ReactPlayer
                        url={this.state.url}
                        controls={true}
                        playing={this.state.visible}
                        pip={true}
                        className="video"
                    // width={"100%"}
                    // height={"100%"}
                    />
                </Modal>
            </div>
        )
    }
}

export default Home