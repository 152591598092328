import React from 'react';
import { Menu, Layout } from 'antd';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import './App.less';
import './App.css';
import 'antd/dist/antd.less';
import Home from './Home';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';

const { SubMenu } = Menu;
const { Header, Content,
  Footer, Sider } = Layout;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItem: "1"
    }
  }
  render() {
    return (
      <div className="App">
        <Router>
          {/* <header> */}
          <Layout style={{ minHeight: "100vh", background: "#faf4e2" }}>
            <Sider
              id="sticky-sidebar"
              breakpoint="md"
              collapsedWidth="0"
              onBreakpoint={broken => {
                console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
              className="sticky-top"
            >
              {/* <div className="logo" /> */}
              <div style={{ paddingTop: "20px", background: "#faf4e2" }}>
                <Link to="/" ><img src="/logo.png" alt="MSP Logo" className="logo" onClick={() => this.setState({ menuItem: "1"})} />
                </Link>
              </div>
              <Menu style={{ marginTop: "-52%" }} className="App-header" theme="light" mode="inline" defaultSelectedKeys={this.state.menuItem}>
                <Menu.Item key="1" className="menu-li" onClick={() => this.setState({ menuItem: "1" })}>
                  <span className={this.state.menuItem === "1" ? "custom-nav" : "custom-nav-hover"}>HOME</span>
                  <Link to="/" />
                </Menu.Item>
                <Menu.Item key="2" className="menu-li" onClick={() => this.setState({ menuItem: "2" })}>
                  <span className={this.state.menuItem === "2" ? "custom-nav" : "custom-nav-hover"}>ABOUT  US</span>
                  <Link to="/about-us" />
                </Menu.Item>
                <Menu.Item key="3" className="menu-li" onClick={() => this.setState({ menuItem: "3" })}>
                  <span className={this.state.menuItem === "3" ? "custom-nav" : "custom-nav-hover"}>CONTACT US</span>
                  <Link to="/contact-us" />
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ minHeight: "100vh", background: "#faf4e2" }}>
              <Content className="main-area">
                <Route exact path="/" component={Home} />
                <Route path="/home" component={Home} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/contact-us" component={ContactUs} />
              </Content>
              <Footer className="main-area" style={{ textAlign: 'center', background: "#faf4e2" }}>
                  <hr className="footer-divider"/>
                  <div className="sm">
                  <a href="https://www.youtube.com/channel/UC2Va8IOnaBQ5WR3MxS2vPUw/videos?view_as=subscriber" target="\_blank"><i className="fab fa-youtube"></i></a>
                  <a href="https://vimeo.com/user121777945" target="\_blank"><i className="fab fa-vimeo-v"></i></a>
                  {/* <a href="#"><i className="fab fa-instagram"></i></a> */}
                  </div>
                  
              </Footer>
            </Layout>
          </Layout>
        </Router>
      </div>
    );
  }
}
export default App;
