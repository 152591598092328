import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Alert, Button, Input, message } from 'antd';

const { TextArea } = Input;

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = { feedback: '', name: '', from_email: '',email: 'makestudioproductions@gmail.com', to_name: 'Make Studio Producions' };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit() {
        const templateId = 'template_lz1heq3';
        this.sendFeedback(templateId, { message: this.state.feedback, from_name: this.state.name, to_name: this.state.to_name, reply_to: this.state.email, from_email: this.state.from_email })
    }
    sendFeedback(templateId, variables) {
        window.emailjs.send(
            'service_zhd3w66', templateId,
            variables
        ).then(res => {
            console.log('Email successfully sent!');
            message.success('Mail sent successfully');
        })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
    render() {
        return (
            <div className="contact-us">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4" style={{ display: "grid", wordBreak: "break-word" }}>
                            <span className="title">
                                Email
                            </span>
                            <a href="mailto:makestudioproductions@gmail.com" className="content">
                                makestudioproductions@gmail.com
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-4" style={{ display: "grid", wordBreak: "break-word" }}>
                            <span className="title">
                                Phone
                            </span>
                            <a href="tel:someone@yoursite.com" className="content">
                                +91-9711738905
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <span className="title" style={{ display: "grid", wordBreak: "break-word" }}>
                                Address
                            </span>
                            <span className="content">
                                605 Sector 8 U.E. Karnal <br /> Harnaya,132001 India
                            </span>
                        </div>
                        {/* <div className="col-sm-12 col-md-4">
                            3
                        </div> */}
                        <div className="col-sm-12" style={{ display: "flex", justifyContent: "center" }}>
                            {/* <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyAFxKwX8JadKZOatMOnYPROooPWJ1HokKE" }}
                                defaultCenter={{ lat: 59.95, lng: 30.33 }}
                                defaultZoom={11}
                            >
                                <AnyReactComponent
                                    lat={59.95}
                                    lng={30.33}
                                    text="My Marker"
                                />
                            </GoogleMapReact> */}
                            <div className="query">
                                <Input placeholder="Your Name" onChange={(e) => this.setState({ name: e.target.value })} />
                                <Input placeholder="Your Email" type="email" onChange={(e) => this.setState({ from_email: e.target.value })} />
                                <TextArea rows={4} placeholder="Your Query" onChange={(e) => this.setState({ feedback: e.target.value })} />
                                <Button onClick={() => this.handleSubmit()}>Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactUs;